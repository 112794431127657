import React from 'react';
import linkedIn from "../../img/icons/linkedIn.svg";
import twitter from "../../img/icons/twitter.svg";
import gitLight from "../../img/icons/gitHub.svg";
import insta from "../../img/icons/instagram.svg";
import vk from "../../img/icons/vk.svg";
import "./footer.css";

const socialMedia = [
  { icon: linkedIn, alt: "LinkedIn" },
  { icon: twitter, alt: "Twitter" },
  { icon: gitLight, alt: "GitHub" },
  { icon: insta, alt: "Instagram" },
  { icon: vk, alt: "VK" },
];

export function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <ul className="social">
            {socialMedia.map((item, index) => (
              <li key={index} className="social__item">
                <a href="#!">
                  <img src={item.icon} alt={item.alt} />
                </a>
              </li>
            ))}
          </ul>
          <div className="copyright">
            <p>© 2023 mironovarym@gmail.com</p>
          </div>
        </div>
      </div>
    </footer>
  );
}